






















































































































import { imgSrc } from "@/const";
import { formatName } from "./VisitsListDayItem.vue";
import { MonitoringRequest } from "@typedefs/api/monitoring";
import StationLink from "@/components/StationLink.vue";

const timeElapsed = (start: Date, end: Date): string => {
  const seconds = Math.round((end.getTime() - start.getTime()) / 1000);
  if (seconds < 60) {
    return `${seconds} second${seconds > 1 ? "s" : ""}`;
  } else if (seconds < 60 * 60) {
    const minutes = Math.round(seconds / 60);
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else if (seconds < 60 * 60 * 60) {
    const hours = (seconds / 60 / 60).toFixed(2);
    return `${hours} hour${Math.round(seconds / 60 / 60) > 1 ? "s" : ""}`;
  }
  return seconds.toString();
};

const timeFormat = (fromDate: Date): string => {
  const fromHours = fromDate && fromDate.getHours();
  const minutes = fromDate.getMinutes().toString().padStart(2, "0");
  if (fromHours === 0) {
    return `12:${minutes}am`;
  } else {
    return `${fromHours <= 12 ? fromHours : fromHours - 12}:${minutes}${
      fromHours < 12 ? "am" : "pm"
    }`;
  }
};

export default {
  name: "VisitSummary",
  components: { StationLink },
  props: {
    item: {
      type: Object,
      required: true,
    },
    futureSearchQuery: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAtGroupLevel(): boolean {
      const group = (this.futureSearchQuery as MonitoringRequest).group;
      return group && group.length !== 0;
    },
    imgSrc() {
      if (this.item.name === "none" || this.item.name === "conflicting-tags") {
        return imgSrc("unidentified");
      }
      return imgSrc(this.item.name);
    },
    visitLength(): string {
      return timeElapsed(this.item.fromDate, this.item.toDate);
    },
    smallGapOfInactivity(): boolean {
      return (
        Math.abs(this.item.fromDate.getTime() - this.item.toDate.getTime()) <
        1000 * 60 * 16
      );
    },
    name() {
      return formatName(this.item.name);
    },
    startTime() {
      return timeFormat(this.item.fromDate);
    },
    recordingsListLink() {
      const firstRecordingId = this.item.item.recordings[0].recId;
      const remainingIds = this.item.item.recordings.map(
        ({ recId }) => `id=${recId}`,
      );
      let remainingIdsQuery = "";
      if (remainingIds.length > 0) {
        remainingIdsQuery = `?${remainingIds.join("&")}`;
      }
      return `/recording/${firstRecordingId}${remainingIdsQuery}`;
    },
  },
  methods: {
    gotoRecordingsForVisit(e) {
      if (!e.ctrlKey && !e.metaKey) {
        e.preventDefault();
        this.$router.push({
          path: this.recordingsListLink,
        });
      }
    },
  },
};
