






















































































import BatteryLevel from "../BatteryLevel.vue";
import { shouldViewAsSuperUser } from "@/utils";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "RecordingProperties",
  components: {
    BatteryLevel,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    recording: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      display: false,
      properties: [
        {
          key: "processingState",
          title: "Processing State",
        },
        { key: "location", title: "Location" },
        {
          key: "relativeToDusk",
          title: "Relative to Dusk",
        },
        {
          key: "relativeToDawn",
          title: "Relative to Dawn",
        },
        { key: "batteryLevel", title: "Battery Level" },
        {
          key: "batteryCharging",
          title: "Battery Charging",
        },
        {
          key: "airplaneModeOn",
          title: "Airplane Mode",
        },
        { key: "version", title: "Version" },
        {
          key: "additionalMetadata",
          title: "Additional Metadata",
        },
      ],
      metaFields: [
        {
          key: "key",
          label: "Additional Metadata",
        },
        {
          key: "data",
          label: "",
        },
      ],
    };
  },
  computed: {
    isSuperUserAndViewingAsSuperUser(): boolean {
      return (
        this.$store.state.User.userData.isSuperUser && shouldViewAsSuperUser()
      );
    },
    parseLocation: function () {
      if (this.recording.location) {
        return `Lat: ${this.recording.location.lat.toFixed(
          2,
        )}, Long: ${this.recording.location.lng.toFixed(2)}`;
      } else {
        return "No location";
      }
    },
    metaItems: function () {
      return (
        this.recording.additionalMetadata
          ?.map((data) => Object.entries(data))
          // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
          .filter(([key, _]) => key !== "tracks")
          .map(([key, data]) => ({ key, data }))
      );
    },
  },
});
