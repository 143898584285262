
















































































































import config from "@/config";
import CptvPlayer from "cptv-player-vue/src/CptvPlayer.vue";
import AddCustomTrackTag from "../components/Video/AddCustomTrackTag.vue";
import api from "@/api";
import DefaultLabels, { TagColours } from "@/const";
import Vue from "vue";

import {
  LimitedTrack,
  LimitedTrackTag,
  TagLimitedRecording,
  Track,
  TrackTag,
  User,
  JwtToken,
} from "@/api/Recording.api";
import { DeviceId } from "@typedefs/api/common";
import { ApiTrackTagRequest } from "@typedefs/api/trackTag";

interface TaggingViewData {
  colours: string[];
  tags: { text: string; value: string }[];
  currentTrackIndex: number;
  currentRecording: TagLimitedRecording | null;
  loading: boolean;
  taggingPending: boolean;
  readyToPlay: boolean;
  tracks: LimitedTrack[];
  history: {
    tracks: LimitedTrack[];
    trackIndex: number;
    recording: TagLimitedRecording;
    tag: LimitedTrackTag;
  }[];
  nextTrackOrRecordingTimeout: number;
  currentTimeout: number | null;
  fileSize: number;
}

export default Vue.extend({
  name: "TaggingView",
  components: { CptvPlayer, AddCustomTrackTag },
  data(): TaggingViewData {
    return {
      colours: TagColours,
      tags: [
        ...DefaultLabels.quickTagLabels().map((x) => ({ text: x, value: x })),
        { text: "mustelid", value: "mustelid" },
        ...DefaultLabels.otherTagLabels(),
      ],
      tracks: [],
      history: [],
      currentRecording: null,
      currentTrackIndex: 0,

      loading: true,
      taggingPending: false,
      readyToPlay: false,
      nextTrackOrRecordingTimeout: 0,
      currentTimeout: null,
      fileSize: 0,
    };
  },
  methods: {
    playerReady() {
      this.readyToPlay = true;
      this.currentTrackIndex = 0;
    },
    prevRecording() {},

    async undo() {
      const { recording, tracks, trackIndex, tag } = this.history.pop();
      await this.deleteTag(tag, recording, tracks[trackIndex]);
      this.currentRecording = recording;
      this.tracks = tracks;
      this.currentTrackIndex = trackIndex;
    },
    async deleteVideo() {
      this.readyToPlay = false;
      await api.recording.del(this.currentRecording.RecordingId);

      await this.nextRecording();
    },
    isTagged(tagValue: string): boolean {
      if (this.currentRecording && this.tracks && this.currentTrack) {
        return (
          this.currentTrack.tags && this.currentTrack.tags.includes(tagValue)
        );
      }
      return false;
    },
    markTrackAsSkipped() {
      const synthesisedTag = {
        TrackTagId: -1,
        what: "skipped",
      };
      this.currentTrack.tags.push(synthesisedTag);
      this.currentTrack.needsTagging = false;
      this.history.push({
        trackIndex: this.currentTrackIndex,
        tracks: this.tracks,
        recording: this.currentRecording,
        tag: synthesisedTag,
      });
      this.primeNextTrack(3);
    },
    nextTrackOrRecording() {
      if (this.allTracksInRecordingAreTaggedByHuman) {
        this.nextRecording();
      } else {
        // Advance to next untagged track
        let nextIndex = this.currentTrackIndex;
        while (
          nextIndex < this.tracks.length &&
          !this.tracks[nextIndex].needsTagging
        ) {
          nextIndex++;
        }
        this.currentTrackIndex = nextIndex;
      }
    },
    async nextRecording() {
      const currentDeviceId =
        this.currentRecording && this.currentRecording.deviceId;
      this.tracks = [];
      this.readyToPlay = false;
      this.loading = true;
      await this.getRecording(currentDeviceId);
      this.loading = false;
    },
    async nextRecordingUnbiased() {
      this.tracks = [];
      this.readyToPlay = false;
      this.loading = true;
      await this.getRecording();
      this.loading = false;
    },
    addCustomTag(tag: ApiTrackTagRequest) {
      this.addTag(tag.what);
    },
    async addTag(tagLabel: string) {
      const recordingId = this.currentRecording.RecordingId;
      const currentTrack = this.tracks[this.currentTrackIndex];
      const trackId = currentTrack.trackId;
      const tag = { what: tagLabel, confidence: 0.85 };
      this.taggingPending = true;
      const addTrackTagResponse = await api.recording.addTrackTag(
        tag,
        recordingId,
        trackId,
        this.currentRecording.tagJWT as unknown as JwtToken<TrackTag>,
      );
      this.taggingPending = false;
      if (addTrackTagResponse.success) {
        const { result } = addTrackTagResponse;
        if (result.success) {
          // Add the track tag to this.data.tracks:
          currentTrack.tags.push(tagLabel);
          currentTrack.needsTagging = false;
          this.history.push({
            trackIndex: this.currentTrackIndex,
            tracks: this.tracks,
            recording: this.currentRecording,
            tag: {
              what: tagLabel,
              TrackTagId: result.trackTagId,
            },
          });
          this.primeNextTrack(3);
        }
      }
    },
    async deleteTag(
      tag: LimitedTrackTag,
      recording: TagLimitedRecording,
      track: LimitedTrack,
    ) {
      if (tag.what !== "skipped") {
        this.taggingPending = true;
        const { success } = await api.recording.deleteTrackTag(
          recording.RecordingId,
          track.trackId,
          tag.TrackTagId,
        );
        this.taggingPending = false;
        if (success) {
          // eslint-disable-next-line require-atomic-updates
          track.tags = track.tags.filter((item) => item !== tag.what);
          // eslint-disable-next-line require-atomic-updates
          track.needsTagging = true;
        }
      } else {
        // Just remove synthetic 'skipped' tag
        track.tags = track.tags.filter((item) => item !== "skipped");
        track.needsTagging = true;
      }
    },
    primeNextTrack(tillNext: number) {
      this.nextTrackOrRecordingTimeout = tillNext;
      if (this.currentTimeout) {
        clearTimeout(this.currentTimeout);
      }
      this.currentTimeout = setTimeout(() => {
        if (tillNext !== 0) {
          this.primeNextTrack(tillNext - 1);
        } else {
          clearTimeout(this.currentTimeout);
          this.nextTrackOrRecording();
        }
      }, 300);
    },
    async getRecording(biasToDeviceId?: DeviceId): Promise<boolean> {
      const recordingResponse = await api.recording.needsTag(biasToDeviceId);
      if (recordingResponse.success) {
        const {
          result: { rows },
        } = recordingResponse;
        if (rows.length) {
          const recording = rows[0];
          this.fileSize = recording.fileSize;
          // Make sure it's not a recording we've seen before and skipped tracks from.
          if (
            this.history.find(
              (prev) => prev.recording.RecordingId === recording.RecordingId,
            ) !== undefined
          ) {
            return await this.getRecording();
          }

          if (recording.tracks.length) {
            this.tracks = recording.tracks
              .map((track) => ({
                ...(track as object),
                tags: [],
              }))
              .filter((track) => (track as any).needsTagging);
          }
          this.currentRecording = recording;

          // Advance to next untagged track
          let nextIndex = 0;
          while (
            nextIndex < this.tracks.length &&
            !this.tracks[nextIndex].needsTagging
          ) {
            nextIndex++;
          }
          this.currentTrackIndex = nextIndex;
        }
      }
      return recordingResponse.success;
    },
  },
  computed: {
    numTagged(): number {
      return this.history.filter(({ tag }) => tag.what !== "skipped").length;
    },
    numSkipped(): number {
      return this.history.filter(({ tag }) => tag.what === "skipped").length;
    },
    allTracksInRecordingAreTaggedByHuman(): boolean {
      return (
        this.tracks.filter((track) => track.needsTagging === false).length ===
        this.tracks.length
      );
    },
    currentTrackIsAlreadyTagged(): boolean {
      return this.currentTrack && !this.currentTrack.needsTagging;
    },
    readyToTag(): boolean {
      return (
        !this.taggingPending &&
        this.currentRecording &&
        this.currentTrack &&
        !this.loading &&
        this.readyToPlay &&
        this.nextTrackOrRecordingTimeout === 0
      );
    },
    currentTrack(): Track {
      if (this.tracks && this.currentTrackIndex < this.tracks.length) {
        return this.tracks[this.currentTrackIndex];
      }
      // NOTE: This is technically an error, but we don't really want to return undefined from this
      return this.tracks && this.tracks[0];
    },
    cTrack() {
      return {
        trackId: this.currentTrack && this.currentTrack.id,
        start: (this.currentTrack && this.currentTrack.start) || 0,
      };
    },
    currentUser(): User {
      return this.$store.state.User.userData;
    },
    fileSource(): string | false {
      if (this.currentRecording) {
        return `${config.api}/api/v1/signedUrl?jwt=${this.currentRecording.recordingJWT}`;
      }
      return false;
    },
  },
  async created() {
    await this.nextRecording();
  },
});
