






















































































































































































































































































































































































import api from "@/api";
import { ApiScheduleResponse, ScheduleConfig } from "@typedefs/api/schedule";
import { ApiAudiobaitFileResponse } from "@typedefs/api/file";
import { FileId, ScheduleId, UserId } from "@typedefs/api/common";
import { ApiDeviceResponse } from "@typedefs/api/device";
import AudioPlayerButton from "@/components/AudioPlayerButton.vue";
import Vue from "vue";
import config from "../config";

const newSchedule = (): ScheduleConfig => ({
  allsounds: [],
  combos: [],
  controlNights: 0,
  playNights: 0,
  description: "",
  startday: 0,
});

const mapSchedule = (schedule: ScheduleConfig): ScheduleConfig => {
  const trimToHoursMinutes = (time: string) => {
    if (time) {
      const parts = time.split(":");
      return `${parts[0]}:${parts[1]}`;
    }
    return time;
  };
  schedule.combos = schedule.combos
    .map((combo) => ({
      waits: combo.waits.map(Number),
      every: parseInt(combo.every.toString()),
      volumes: combo.volumes.map(Number),
      sounds: combo.sounds.map(String),
      from: trimToHoursMinutes(combo.from),
      until: trimToHoursMinutes(combo.until),
    }))
    .filter((combo) => !!combo.from && !!combo.until);
  schedule.playNights = parseInt(schedule.playNights.toString());
  schedule.controlNights = parseInt(schedule.controlNights.toString());
  schedule.startday = parseInt(schedule.startday.toString());
  return schedule;
};

const populateAllSounds = (
  schedule: ScheduleConfig,
  availableSounds: ApiAudiobaitFileResponse[],
) => {
  const usedSounds = Object.keys(
    schedule.combos.reduce((acc, combo) => {
      for (const sound of combo.sounds) {
        acc[sound] = true;
      }
      return acc;
    }, {}),
  );
  if (usedSounds.includes("random") || usedSounds.includes("same")) {
    // We need to add all sounds to the schedule
    schedule.allsounds = availableSounds.map(({ id }) => id);
  } else {
    schedule.allsounds = usedSounds.map(Number);
  }
  return schedule;
};

export default {
  name: "SchedulesView",
  components: {
    AudioPlayerButton,
  },
  data() {
    return {
      schedules: [],
      creatingSchedule: false,
      assigningToDevices: false,
      confirmFileRemoval: false,
      managingSounds: false,
      uploadingSounds: false,
      pendingSchedule: newSchedule(),
      pendingSounds: [],
      files: [],
      devices: [],
      selectedDevices: [],
      fetchingDevices: false,
      assigningTo: null,
      removingFile: null,
    };
  },
  computed: {
    soundOptions() {
      return [
        {
          value: "random",
          text: "A random sound",
        },
        {
          value: "same",
          text: "Repeat the last sound",
        },
        ...this.files.map((file: ApiAudiobaitFileResponse) => ({
          value: file.id,
          text: file.details.name,
        })),
      ];
    },
    schedulesTable() {
      return this.schedules.map(({ schedule, id }: ApiScheduleResponse) => ({
        id,
        name: schedule.description,
        devices: this.assignedDevices[id] || [],
      }));
    },
    audioDeviceOptions() {
      return this.devices.map((device: ApiDeviceResponse) => ({
        name: device.deviceName,
        id: device.id,
      }));
    },
    assignedDevices() {
      if (this.devices.length) {
        return this.devices.reduce((acc, device) => {
          if (device.scheduleId) {
            acc[device.scheduleId] = acc[device.scheduleId] || [];
            acc[device.scheduleId].push(device);
          }
          return acc;
        }, {});
      }
      return {};
    },
    currentUserId(): UserId {
      return this.$store.state.User.userData.id;
    },
  },
  async created() {
    const [schedulesResponse, filesResponse, devicesResponse] =
      await Promise.all([
        api.schedule.getSchedulesForCurrentUser(),
        api.schedule.getAudioBaitFiles(),
        api.device.getDevices(),
      ]);
    if (schedulesResponse.success) {
      this.schedules = schedulesResponse.result.schedules;
    }
    if (filesResponse.success) {
      this.files = filesResponse.result.files;
    }
    if (devicesResponse.success) {
      this.devices = devicesResponse.result.devices;
    }
  },
  methods: {
    createSchedule() {
      this.pendingSchedule = newSchedule();
      this.creatingSchedule = true;
    },
    manageSoundFiles() {
      this.managingSounds = true;
    },
    newSound() {
      this.pendingSounds.push({
        details: {
          name: "",
          description: "",
          originalName: "",
          source: "",
          animal: "",
          sound: "",
        },
        file: null,
      });
    },
    async getAudioSrc(fileId: FileId): Promise<string> {
      const fileResponse = await api.schedule.getAudioBaitFileSource(fileId);
      if (fileResponse.success) {
        return `${config.api}/api/v1/signedUrl?jwt=${fileResponse.result.jwt}`;
      }
    },
    async uploadNewSounds() {
      this.uploadingSounds = true;
      while (this.pendingSounds.length) {
        const sound = ((sound) => {
          sound.details.originalName = (sound.file as File).name;
          return sound;
        })(this.pendingSounds.pop());
        const formData = new FormData();
        formData.append(
          "data",
          JSON.stringify({
            details: sound.details,
            type: "audioBait",
          }),
        );
        formData.append("file", sound.file);
        const fileUploadResponse =
          await api.schedule.uploadAudiobaitFile(formData);
        if (fileUploadResponse.success) {
          this.files.push({
            ...sound,
            id: fileUploadResponse.result.id,
            userId: this.currentUserId,
          });
        }
      }
      this.uploadingSounds = false;
      this.managingSounds = false;
    },
    async createPendingSchedule() {
      const typedSchedule = populateAllSounds(
        mapSchedule(this.pendingSchedule),
        this.files,
      );

      this.schedules.push({ id: "-", schedule: typedSchedule });
      const createScheduleResponse =
        await api.schedule.createSchedule(typedSchedule);
      if (createScheduleResponse.success) {
        this.schedules[this.schedules.length - 1].id =
          createScheduleResponse.result.id;
      } else {
        this.errorMessage = "Error creating schedule";
        this.schedules.pop();
      }
    },
    async deleteSchedule(id: ScheduleId) {
      const deleteResponse = await api.schedule.deleteSchedule(id);
      if (deleteResponse.success) {
        const index = this.schedules.findIndex(
          (schedule) => schedule.id === id,
        );
        this.schedules.splice(index, 1);
      }
    },
    async assignScheduleToDevices(id: ScheduleId) {
      // Show modal with devices dropdown.
      this.assigningTo = id;
      if (this.assignedDevices[id]) {
        this.selectedDevices = this.assignedDevices[id].map(({ id }) =>
          this.audioDeviceOptions.find((option) => option.id === id),
        );
      }
      this.assigningToDevices = true;
    },
    async assignSelectedDevices() {
      const scheduleId = this.assigningTo;
      if (!scheduleId) {
        return;
      }
      // If there were previously assigned devices that are no longer in the selected list, first remove them.
      let previouslyAssigned = [];
      if (this.assignedDevices[scheduleId]) {
        previouslyAssigned = this.assignedDevices[scheduleId].map(
          ({ id }) => id,
        );
      }
      let deviceIds = this.selectedDevices.map(({ id }) => id);
      const removedIds = previouslyAssigned.filter(
        (id) => !deviceIds.includes(id),
      );
      // Filter out items that already are assigned:
      deviceIds = deviceIds.filter((id) => !previouslyAssigned.includes(id));
      await Promise.all([
        ...removedIds.map((deviceId) =>
          api.device.removeScheduleFromDevice(deviceId, scheduleId),
        ),
        ...deviceIds.map((deviceId) =>
          api.device.assignScheduleToDevice(deviceId, scheduleId),
        ),
      ]);
      for (const device of this.devices) {
        if (deviceIds.includes(device.id)) {
          Vue.set(device, "scheduleId", scheduleId);
        }
        if (removedIds.includes(device.id)) {
          Vue.delete(device, "scheduleId");
        }
      }
      this.selectedDevices = [];
      this.assigningTo = null;
    },
    addRule(combos) {
      combos.push({
        from: "",
        every: 0,
        until: "",
        waits: [],
        sounds: [],
        volumes: [],
      });
    },
    removeRule(rule) {
      const ruleIndex = this.pendingSchedule.combos.indexOf(rule);
      this.pendingSchedule.combos.splice(ruleIndex, 1);
    },
    addSound(rule) {
      rule.waits.push(0);
      rule.volumes.push(10);
      rule.sounds.push("");
    },
    async removeFile(fileId: FileId, force = false) {
      // First check if the file is used by any of our schedules.
      if (
        !force &&
        this.schedules.find(({ schedule }: { schedule: ScheduleConfig }) =>
          schedule.allsounds.includes(fileId),
        )
      ) {
        this.confirmFileRemoval = true;
        this.removingFile = fileId;
      } else {
        const removeResponse = await api.schedule.deleteAudiobaitFile(fileId);
        if (removeResponse.success) {
          const index = this.files.findIndex((file) => file.id === fileId);
          this.files.splice(index, 1);
        }
        this.removingFile = null;
      }
    },
  },
};
