




















import { defineComponent, watch, PropType } from "@vue/composition-api";
import DefaultLabels from "../../const";
import ClassificationsDropdown from "../ClassificationsDropdown.vue";

export default defineComponent({
  name: "SelectTags",
  components: {
    ClassificationsDropdown,
  },
  props: {
    value: {
      type: Object as PropType<{
        tagMode: string;
        tags: string[];
        exclusive: boolean;
      }>,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const typeOptions = DefaultLabels.searchRecordingLabels();

    watch(
      () => props.value,
      () => {
        if (
          props.value.tags &&
          props.value.tags.length > 0 &&
          !DefaultLabels.canHaveSpecifiedTags(props.value.tagMode)
        ) {
          emit("input", {
            tagMode: "tagged",
            tags: props.value.tags,
          });
        }
      },
    );
    return { typeOptions };
  },
});
