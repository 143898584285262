import { render, staticRenderFns } from "./LabelButtonGroup.vue?vue&type=template&id=0c4abff5&"
import script from "./LabelButtonGroup.vue?vue&type=script&lang=ts&"
export * from "./LabelButtonGroup.vue?vue&type=script&lang=ts&"
import style0 from "./LabelButtonGroup.vue?vue&type=style&index=0&id=0c4abff5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports