import { render, staticRenderFns } from "./RecordingsList.vue?vue&type=template&id=3c4a1ceb&scoped=true&"
import script from "./RecordingsList.vue?vue&type=script&lang=ts&"
export * from "./RecordingsList.vue?vue&type=script&lang=ts&"
import style0 from "./RecordingsList.vue?vue&type=style&index=0&id=3c4a1ceb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c4a1ceb",
  null
  
)

export default component.exports