































































































import { mapState } from "vuex";
import { isViewingAsOtherUser } from "@/components/NavBar.vue";
import { shouldViewAsSuperUser } from "@/utils";
import api from "@/api";

interface DeviceImageItem {
  deviceId: number;
  refType: "pov" | "in-situ";
  key: string;
  loading: boolean;
  image: string | null;
  deviceName?: string;
}

interface StationImageItem {
  key: string; // the S3 fileKey
  loading: boolean;
  image: string | null; // URL blob
}

export default {
  name: "StationReferencePhotosTab",
  props: {
    station: {
      required: true,
    },
    group: {
      required: true,
    },
  },
  data() {
    return {
      stationImages: [] as StationImageItem[],
      deviceImages: [] as DeviceImageItem[],
      selectedStationUpload: null as File | null,
      modalImage: null as string | null,
      showModal: false,
    };
  },
  async mounted() {
    // Load existing station-level reference images
    const stationRefKeys =
      (this.station.settings && this.station.settings.referenceImages) || [];
    for (const fileKey of stationRefKeys) {
      const imageItem: StationImageItem = {
        key: fileKey,
        loading: true,
        image: null,
      };
      this.stationImages.push(imageItem);
      api.station.getReferenceImage(this.station.id, fileKey).then((res) => {
        if (res.success) {
          const blob = res.result as Blob;
          imageItem.image = window.URL.createObjectURL(blob);
        }
        imageItem.loading = false;
      });
    }

    // Fetch devices assigned to this station
    //    (adapt this call to however you find devices for a station)
    const devicesRes = await api.station.listDevices(this.station.id);
    if (!devicesRes.success) {
      return;
    }
    const devices = devicesRes.result.devices;

    for (const dev of devices) {
      const refTypes = ["pov", "in-situ"] as const;
      for (const refType of refTypes) {
        const devImg: DeviceImageItem = {
          deviceId: dev.id,
          refType,
          key: `${dev.id}-${refType}`,
          loading: true,
          image: null,
          deviceName: dev.deviceName,
        };
        this.deviceImages.push(devImg);

        try {
          const resp = await api.device.getReferenceImage(dev.id, {
            type: refType,
          });
          if (resp.success) {
            const blob = resp.result as Blob;
            devImg.image = URL.createObjectURL(blob);
          } else {
            // If no image for that type, remove it from the array
            this.deviceImages = this.deviceImages.filter((i) => i !== devImg);
          }
        } catch (err) {
          // If 404 or similar, remove the placeholder
          this.deviceImages = this.deviceImages.filter((i) => i !== devImg);
        }
        devImg.loading = false;
      }
    }
  },
  methods: {
    async deleteStationImage(fileKey: string) {
      this.stationImages = this.stationImages.filter(
        (img) => img.key !== fileKey,
      );
      await api.station.deleteReferenceImage(this.station.id, fileKey);
    },

    async uploadSelectedStationImage() {
      if (!this.selectedStationUpload) {
        return;
      }
      const file = this.selectedStationUpload;

      const resizedBlob = await this.resizeImage(file);

      const resp = await api.station.uploadReferenceImage(
        this.station.id,
        resizedBlob,
      );
      if (resp.success) {
        const { fileKey } = resp.result;
        this.stationImages.push({
          key: fileKey,
          loading: false,
          image: URL.createObjectURL(resizedBlob),
        });
      }

      this.selectedStationUpload = null;
    },

    resizeImage(file: File): Promise<Blob> {
      return new Promise<Blob>((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = document.createElement("img");
          image.src = reader.result as string;
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const maxSize = 1600; // e.g. maximum dimension
            const ratio = image.width / image.height;

            if (ratio > 1) {
              canvas.width = maxSize;
              canvas.height = Math.floor(canvas.width / ratio);
            } else {
              canvas.height = maxSize;
              canvas.width = Math.floor(canvas.height * ratio);
            }
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.8);
          };
        };
        reader.readAsDataURL(file);
      });
    },

    deviceLabel(img: DeviceImageItem) {
      return `${img.deviceName || "Device #" + img.deviceId} - ${img.refType}`;
    },
    openImageInModal(image: string) {
      this.showModal = true;
      this.modalImage = image;
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => (state as any).User.userData,
    }),
    userIsGroupAdmin() {
      const su =
        this.currentUser.globalPermission === "write" &&
        (isViewingAsOtherUser() || shouldViewAsSuperUser());
      return su || (this.group && this.group.admin);
    },
  },
};
