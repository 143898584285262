















































import VisitSummary from "@/components/VisitSummary.vue";
import { toStringTodayYesterdayOrDateInNights } from "@/helpers/datetime";
import { NewVisit } from "@/api/Monitoring.api";
import { imgSrc } from "@/const";

export interface ItemData {
  kind: "dataRow" | "dataSeparator" | "noActivity" | "powerEvent" | "duskDawn";
  name: string;
  fromDate: Date;
  toDate: Date;
  time: string;
  item: NewVisit;
  groupName?: string;
}

// NOTE: Sorting precedence for visit tags displayed as small summary icons
const tagPrecedence = [
  "conflicting tags",
  "unidentified",
  "none",
  "mustelid",
  "cat",
  "possum",
  "hedgehog",
  "rodent",
  "leporidae",
];

export const formatName = (name: string): string => {
  return name
    .substr(0, 1)
    .toUpperCase()
    .concat(name.slice(1))
    .replace(/-/g, " ");
};

export default {
  components: {
    VisitSummary,
  },
  name: "VisitsListDayItem",
  props: {
    items: {
      type: Array,
      required: true,
    },
    futureSearchQuery: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    reverse(arr) {
      return [...arr].reverse();
    },
    imgSrc(what: string) {
      if (what === "none" || what === "conflicting-tags") {
        return imgSrc("unidentified");
      }
      return imgSrc(what);
    },
    formatNameAndCount(name: string, count: number): string {
      const formattedName = formatName(name);
      if (count > 1) {
        // Find the display name for the tag, pluralise it:
        return `${count} ${formattedName}${
          count !== 1 &&
          !formattedName.endsWith("s") &&
          !formattedName.endsWith("ae") // Leporidae
            ? "s"
            : ""
        }`;
      }
      return formattedName;
    },
    toggleExpanded() {
      if (this.hasVisits) {
        this.expanded = !this.expanded;
      }
    },
  },
  computed: {
    hasVisits(): boolean {
      return this.visitClasses.length !== 0;
    },
    relativeDay() {
      const dayChunk = this.reversedItems as ItemData[];
      const fromDate = dayChunk[0].fromDate;
      return toStringTodayYesterdayOrDateInNights(fromDate);
    },
    reversedItems(): Array<ItemData[]> {
      return [...this.items].reverse();
    },
    visitClasses(): [string, number][] {
      const dayChunk = this.items as ItemData[];
      return Object.entries(
        dayChunk
          .filter((item) => item.name && item.kind === "dataRow")
          .map((item) => {
            const name = item.name.replace(/ /g, "-");
            return {
              name,
              priority: tagPrecedence.indexOf(name),
            };
          })
          .sort((a, b) => {
            if (a.priority === -1 && b.priority > -1) {
              return 1;
            } else if (b.priority === -1 && a.priority > -1) {
              return -1;
            } else if (a.priority === -1 && b.priority === -1) {
              if (a.name === b.name) {
                return 0;
              }
              return a.name > b.name ? 1 : -1;
            }
            return a.priority - b.priority;
          })
          .reduce((acc, item) => {
            const name = item.name;
            acc[name] = acc[name] || 0;
            acc[name]++;
            return acc;
          }, {}),
      );
    },
  },
};
