
































import { ApiTrackTagRequest } from "@typedefs/api/trackTag";
import DefaultLabels from "../../const";

export default {
  name: "AddCustomTrackTag",
  props: {
    allowConfidence: {
      type: Boolean,
      default: true,
    },
    allowComment: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      whatTag: "cat",
      confidence: 0.8,
      confidenceOptions: [
        { value: 0.4, text: "low" },
        { value: 0.6, text: "mid" },
        { value: 0.8, text: "high" },
      ],
      comment: "",
    };
  },
  computed: {
    whatOptions(): { value: string; text: string }[] {
      const options = new Map();
      for (const option of DefaultLabels.trackLabels()) {
        options.set(option.text, { value: option.value, text: option.text });
      }
      return Array.from(options.values()).sort((a, b) =>
        a.text.localeCompare(b.text),
      );
    },
    pinnedTag() {
      return this.$store.state.Video.pinnedLabels.includes(this.whatTag);
    },
  },
  methods: {
    quickTag() {
      const tag: ApiTrackTagRequest = {
        confidence: this.confidence,
        what: this.whatTag,
      };
      this.$emit("addTag", tag);
    },
    togglePinTag() {
      this.$store.commit("Video/pinnedLabels", this.whatTag);
    },
  },
};
