



























import { startOfEvening } from "@/helpers/datetime";
import { NewVisit } from "@/api/Monitoring.api";
import VisitsListDayItem, {
  ItemData,
} from "@/components/VisitsListDayItem.vue";

interface VisitsListData {
  visitsChunkedByDay: Array<Array<ItemData[]>>;
  atEnd: boolean;
  loadedVisitsCount: number;
}

export default {
  name: "VisitsList",
  components: {
    VisitsListDayItem,
  },
  props: {
    visits: {
      type: Array,
      required: true,
    },
    groupName: {
      type: String,
      required: false,
    },
    queryPending: {
      type: Boolean,
      required: true,
    },
    viewVisitsQuery: {
      type: Object,
      default: () => ({}),
    },
    allLoaded: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visits() {
      // TODO(jon): Chunk this by "night" of recording, so that I can easily see what happened in
      //  the previous night from dusk till dawn.
      // For each day, have a summary of species at the top.
      // Keep lazy loading until we have each day, so that we can display proper summaries for each day.

      // Should I show periods of "no activity"? Yes, I think that makes things clearer.
      let prevFromDate = null;
      let prevDate = null;
      const visits = this.visits as NewVisit[];
      if (visits.length === 0) {
        this.visitsChunkedByDay = [];
        this.loadedRecordingsCount = 0;
        return;
      } else {
        prevFromDate = new Date(visits[visits.length - 1].timeStart);
        prevDate = prevFromDate;
      }
      // Slice from last recordings count, so we're only processing new recordings.
      const newVisits = visits.slice(this.loadedVisitsCount);
      const items = [];
      // TODO(jon): Intersperse power-on/power-off events and dusk/dawn times
      if (newVisits.length && this.loadedVisitsCount !== 0) {
        items.push({
          kind: "dataSeparator",
          hour: prevFromDate,
          date: prevFromDate,
        });
      }

      for (const visit of newVisits) {
        const fromDate = new Date(visit.timeStart);
        const toDate = new Date(visit.timeEnd);
        if (
          (newVisits.filter((v) => v.hasOwnProperty("device")).length === 1 && // Edge case with only 1 visit
            this.loadedVisitsCount === 0 &&
            items.length <= 1) ||
          prevFromDate === null ||
          startOfEvening(fromDate).getTime() !==
            startOfEvening(prevFromDate).getTime()
        ) {
          const item = {
            kind: "dataSeparator",
            hour: fromDate,
            date: fromDate,
          };
          items.push(item);
          prevFromDate = fromDate;
        }

        if (
          prevDate &&
          Math.abs(prevDate.getTime() - toDate.getTime()) < 1000 * 60 * 60 * 8
        ) {
          // Push "no activity block" if less than 8 eight hours of inactivity
          items.push({
            kind: "noActivity",
            name: "no activity",
            fromDate: toDate,
            toDate: prevDate,
            time: toDate.toLocaleTimeString(),
            item: { recordings: [] },
          });
        }
        const duskDawn = ["Sunset", "Sunrise"];
        const itemData: ItemData = {
          kind: duskDawn.includes(visit.classification)
            ? "duskDawn"
            : "dataRow",
          name: visit.classification,
          groupName: this.groupName,
          fromDate,
          toDate,
          time: fromDate.toLocaleTimeString(),
          item: visit,
        };
        prevDate = fromDate;
        items.push(itemData);
      }
      // Now calculate chunks of days and hour groupings
      {
        const chunks = [];
        let current = chunks;
        for (const item of items) {
          if (item.kind === "dataSeparator") {
            if (item.hasOwnProperty("date")) {
              chunks.push([]);
              current = chunks[chunks.length - 1];
            }
          } else {
            if (current === chunks && current.length === 0) {
              chunks.push([]);
              current = chunks[chunks.length - 1];
            }
            current.push(item);
          }
        }
        if (current.length === 0) {
          chunks.pop();
        }
        if (chunks.length === 0) {
          // We've reached the end of the recordings.
          this.atEnd = true;
        }
        this.visitsChunkedByDay.push(
          ...chunks.filter((chunk) => chunk.length !== 0),
        );
      }
    },
  },
  beforeDestroy() {
    this.unload();
  },
  beforeUpdate() {
    this.unload();
  },
  updated() {
    if (this.visits.length !== this.loadedVisitsCount) {
      this.loadedVisitsCount = this.visits.length;
      // Setup next intersection observer to see the page has scrolled enough to load more items
      this.observer = new IntersectionObserver(this.intersectionChanged);
      // Observe intersections of cards
      const maxY = [];
      // Just observe the nth to last item, and when it comes into view, we load more, and disconnect the observer.
      const n = 3;
      for (const ref of Object.values(this.$refs)) {
        if ((ref as any[]).length !== 0) {
          if (ref[0] && ref[0].$el) {
            const bounds =
              ref[0].$el.getBoundingClientRect &&
              ref[0].$el.getBoundingClientRect();
            if (bounds) {
              maxY.push([bounds.y, ref[0].$el]);
              maxY.sort((a, b) => b[0] - a[0]);
              if (maxY.length > n) {
                maxY.pop();
              }
            }
          }
        }
      }
      if (maxY.length) {
        const observerTrigger = maxY[maxY.length - 1][1];
        this.$refs["list-container"].style.height = `${maxY[0][0]}px`;
        //console.log("Observing", observerTrigger);
        this.observer && this.observer.observe(observerTrigger);
      } else {
        this.$refs["list-container"].style.height = "auto";
      }
    }
  },
  methods: {
    intersectionChanged(entries: IntersectionObserverEntry[]) {
      for (const intersectionEvent of entries) {
        if (intersectionEvent.isIntersecting) {
          this.observer.unobserve(intersectionEvent.target);
          //console.log("Emitting load more", intersectionEvent);
          this.$emit("load-more");
        }
      }
    },
    reverse(arr) {
      return [...arr].reverse();
    },
    unload() {
      this.observer && this.observer.disconnect();
    },
  },
  data(): VisitsListData {
    return {
      visitsChunkedByDay: [],
      atEnd: false,
      loadedVisitsCount: 0,
    };
  },
};
