var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',[_c('label',[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('multiselect',{attrs:{"value":_vm.selectedValues,"options":_vm.options,"multiple":true,"placeholder":_vm.placeholder,"loading":_vm.fetching,"track-by":"uid","label":"name","data-cy":"device-select"},on:{"input":_vm.updateSelected,"open":_vm.maybeInitialiseValues},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('span',{staticClass:"multiselect__tag"},[(option.type === 'group')?_c('font-awesome-icon',{attrs:{"icon":"users","size":"xs"}}):(option.type === 'device')?_c('font-awesome-icon',{attrs:{"icon":option.kind === 'thermal'
              ? 'video'
              : option.kind === 'audio'
                ? 'music'
                : 'microchip',"size":"xs"}}):(option.type === 'station')?_c('font-awesome-icon',{attrs:{"icon":"map-marker-alt","size":"xs"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"tag"},[_vm._v(_vm._s(option.name))]),_vm._v(" "),(option.type === 'group' && option.deviceCount)?_c('span',{staticClass:"tag"},[_vm._v("\n          ("+_vm._s(option.deviceCount)+" device"),(option.deviceCount > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(")\n        ")]):_vm._e(),_vm._v(" "),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"aria-hidden":"true","tabindex":"1"},on:{"click":function (_) { return remove(option); },"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return remove(option)}}})],1)]}},{key:"option",fn:function(ref){
                var ref_option = ref.option;
                var type = ref_option.type;
                var name = ref_option.name;
                var kind = ref_option.kind;
return [_c('span',[(type === 'group')?_c('font-awesome-icon',{attrs:{"icon":"users","size":"xs"}}):(type === 'device')?_c('font-awesome-icon',{attrs:{"icon":kind === 'thermal'
              ? 'video'
              : kind === 'audio'
                ? 'music'
                : 'microchip',"size":"xs"}}):(type === 'station')?_c('font-awesome-icon',{attrs:{"icon":"map-marker-alt","size":"xs"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"option"},[_vm._v(_vm._s(name))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }