






































































































































































































import { mapState } from "vuex";
import { toStringTodayYesterdayOrDate } from "@/helpers/datetime";
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
} from "@vue/composition-api";
import DeviceApi from "@/api/Device.api";
import {
  ApiDeviceHistorySettings,
  AudioModes,
  WindowsSettings,
} from "@typedefs/api/device";

export default defineComponent({
  name: "DeviceDetail",
  props: {
    software: {
      type: Object,
      required: true,
    },
    deviceId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const settings = ref<ApiDeviceHistorySettings>(null);
    const fields = [
      { key: "name", label: "Setting" },
      { key: "value", label: "Value" },
      { key: "actions", label: "Actions" },
    ];

    const showCustomModal = ref(false);

    const fetchSettings = async () => {
      try {
        const response = await DeviceApi.getSettingsForDevice(props.deviceId);
        debugger;
        if (response.success) {
          settings.value = response.result.settings;
        }
      } catch (e) {
        //console.error(e);
      }
    };

    const toggleUseLowPowerMode = async (setting) => {
      if (setting.name === "Use Low Power Mode") {
        const response = await DeviceApi.toggleUseLowPowerMode(props.deviceId);
        if (response.success) {
          settings.value = response.result.settings;
        }
      }
    };

    const setDefaultRecordingWindows = async () => {
      const response = await DeviceApi.setDefaultRecordingWindows(
        props.deviceId,
      );
      if (response.success) {
        settings.value = response.result.settings;
      }
    };

    const set24HourRecordingWindows = async () => {
      const response = await DeviceApi.set24HourRecordingWindows(
        props.deviceId,
      );
      if (response.success) {
        settings.value = response.result.settings;
      }
    };
    const currentWindowsType = computed(() => {
      if (!settings.value || !settings.value.windows) {
        return "unknown";
      }
      const { powerOn, powerOff, startRecording, stopRecording } =
        settings.value.windows;
      if (
        powerOn === "-30m" &&
        powerOff === "+30m" &&
        startRecording === "-30m" &&
        stopRecording === "+30m"
      ) {
        return "default";
      } else if (
        powerOn === "12:00" &&
        powerOff === "12:00" &&
        startRecording === "12:00" &&
        stopRecording === "12:00"
      ) {
        return "24hour";
      } else {
        return "custom";
      }
    });

    const formatTime = (timeString) => {
      if (timeString[0] === "+" || timeString[0] === "-") {
        return timeString;
      }
      const [hours, minutes] = timeString.split(":");
      return `${hours}:${minutes}`;
    };

    const formatRecordingWindows = (windows: WindowsSettings) => {
      if (!(windows.powerOn && windows.powerOff)) {
        return `Start Recording: ${formatTime(
          windows.startRecording,
        )}, Stop Recording: ${formatTime(windows.stopRecording)}`;
      } else {
        return `Power On: ${formatTime(
          windows.powerOn!,
        )}, Power Off: ${formatTime(
          windows.powerOff!,
        )}, Start Recording: ${formatTime(
          windows.startRecording,
        )}, Stop Recording: ${formatTime(windows.stopRecording)}`;
      }
    };

    const useLowPowerMode = computed<boolean>({
      get: () => {
        return (
          (settings.value as ApiDeviceHistorySettings)?.thermalRecording
            ?.useLowPowerMode ?? false
        );
      },
      set: async (val: boolean) => {
        (settings.value as ApiDeviceHistorySettings).thermalRecording = {
          useLowPowerMode: val,
          updated: new Date().toISOString(),
        };
        await DeviceApi.updateDeviceSettings(props.deviceId, settings.value);
      },
    });
    const settingsTable = computed(() => {
      const rows = [
        {
          name: "Use Low Power Mode",
          value: settings.value?.thermalRecording?.useLowPowerMode ?? false,
          synced:
            settings.value?.thermalRecording?.useLowPowerMode !== undefined &&
            settings.value?.synced
              ? "Yes"
              : "No",
        },
        {
          name: "Recording Windows",
          value: settings.value?.windows
            ? formatRecordingWindows(settings.value.windows)
            : "Not set",
          synced: settings.value?.synced ? "Yes" : "No",
        },
      ];

      return rows;
    });

    const deviceModel = ref<"pi" | "tc2">();
    const isTc2Device = computed<boolean>(() => {
      return deviceModel.value === "tc2";
    });
    const defaultWindows = {
      powerOn: "-30m",
      powerOff: "+30m",
      startRecording: "-30m",
      stopRecording: "+30m",
    };
    const savingAudioSettings = ref<boolean>(false);
    const audioMode = computed<AudioModes>({
      get: () => {
        return settings.value?.audioRecording?.audioMode ?? "Disabled";
      },
      set: async (val: AudioModes) => {
        if (settings.value) {
          settings.value.audioRecording = {
            ...settings.value.audioRecording,
            audioMode: val,
            updated: new Date().toISOString(),
          };
          savingAudioSettings.value = true;
          await DeviceApi.updateDeviceSettings(props.deviceId, settings.value);
          savingAudioSettings.value = false;
        }
      },
    });
    const audioModeExplanation = computed<string>(() => {
      debugger;
      switch (audioMode.value) {
        case "AudioOnly":
          return "Records audio in a 24-hour window and disables thermal recording.";
        case "AudioOrThermal":
          return "Records audio outside of the thermal recording window.";
        case "AudioAndThermal":
          return "Records audio in a 24-hour window; however, the camera cannot record during the 1 minute of audio recording.";
        default:
          return "";
      }
    });
    // Audio Mode Options
    const audioModeOptions = [
      { value: "Disabled", text: "Disabled" },
      { value: "AudioOnly", text: "Audio Only" },
      { value: "AudioAndThermal", text: "Audio and Thermal" },
      { value: "AudioOrThermal", text: "Audio or Thermal" },
    ];
    const recordingWindowSetting = computed<"default" | "always" | "custom">({
      get: () => {
        const s = settings.value as ApiDeviceHistorySettings;
        if (
          s &&
          s.windows &&
          s.windows.startRecording &&
          s.windows.stopRecording
        ) {
          const start = s.windows.startRecording;
          const stop = s.windows.stopRecording;
          if (
            (start.startsWith("+") || start.startsWith("-")) &&
            (stop.startsWith("+") || stop.startsWith("-"))
          ) {
            return "default";
          } else if (start === stop) {
            return "always";
          } else {
            return "custom";
          }
        } else {
          return "default";
        }
      },
      set: async (val: "default" | "always" | "custom") => {
        if (settings.value) {
          if (val === "default" && settings.value) {
            settings.value.windows = {
              ...defaultWindows,
              updated: new Date().toISOString(),
            };
          } else if (val === "always") {
            settings.value.windows = {
              ...(!isTc2Device.value
                ? {
                    powerOn: "12:00",
                    powerOff: "12:00",
                  }
                : {}),
              startRecording: "12:00",
              stopRecording: "12:00",
              updated: new Date().toISOString(),
            };
          } else {
            settings.value.windows = {
              ...(!isTc2Device.value
                ? {
                    powerOn: "09:00",
                    powerOff: "17:00",
                  }
                : {}),
              startRecording: "09:00",
              stopRecording: "17:00",
              updated: new Date().toISOString(),
            };
          }

          await DeviceApi.updateDeviceSettings(props.deviceId, settings.value);
        }
      },
    });

    const customPowerWindowStart = computed<string>({
      get: () => {
        if (settings.value) {
          return (
            (settings.value as ApiDeviceHistorySettings).windows
              ?.startRecording || ""
          );
        } else {
          return "12:00";
        }
      },
      set: async (val: string) => {
        if (settings.value) {
          settings.value.windows = settings.value.windows || {
            ...defaultWindows,
            updated: new Date().toISOString(),
          };
          settings.value.windows.powerOn = val;
          settings.value.windows.updated = new Date().toISOString();
          await DeviceApi.updateDeviceSettings(props.deviceId, settings.value);
        }
      },
    });

    const customPowerWindowStop = computed<string>({
      get: () => {
        if (settings.value) {
          return (
            (settings.value as ApiDeviceHistorySettings).windows
              ?.stopRecording || ""
          );
        } else {
          return "12:00";
        }
      },
      set: async (val: string) => {
        if (settings.value) {
          settings.value.windows = settings.value.windows || {
            ...defaultWindows,
            updated: new Date().toISOString(),
          };
          settings.value.windows.powerOff = val;
          settings.value.windows.updated = new Date().toISOString();
          await DeviceApi.updateDeviceSettings(props.deviceId, settings.value);
        }
      },
    });

    const customRecordingWindowStart = computed<string>({
      get: () => {
        if (settings.value) {
          return (
            (settings.value as ApiDeviceHistorySettings).windows
              ?.startRecording || ""
          );
        } else {
          return "12:00";
        }
      },
      set: async (val: string) => {
        if (settings.value) {
          settings.value.windows = settings.value.windows || {
            ...defaultWindows,
            updated: new Date().toISOString(),
          };
          settings.value.windows.startRecording = val;
          settings.value.windows.updated = new Date().toISOString();
          await DeviceApi.updateDeviceSettings(props.deviceId, settings.value);
        }
      },
    });

    const customRecordingWindowStop = computed<string>({
      get: () => {
        if (settings.value) {
          return (
            (settings.value as ApiDeviceHistorySettings).windows
              ?.stopRecording || ""
          );
        } else {
          return "12:00";
        }
      },
      set: async (val: string) => {
        if (settings.value) {
          settings.value.windows = settings.value.windows || {
            ...defaultWindows,
            updated: new Date().toISOString(),
          };
          settings.value.windows.stopRecording = val;
          settings.value.windows.updated = new Date().toISOString();
          await DeviceApi.updateDeviceSettings(props.deviceId, settings.value);
        }
      },
    });
    const initialized = ref<boolean>(false);

    watch(
      () => audioMode.value,
      async () => {
        if (settings.value) {
          savingAudioSettings.value = true;
          await DeviceApi.updateDeviceSettings(props.deviceId, settings.value);
          savingAudioSettings.value = false;
        }
      },
    );

    onMounted(async () => {
      await fetchSettings();
      initialized.value = true;
      const res = await DeviceApi.getDeviceModel(props.deviceId);
      if (res) {
        deviceModel.value = res;
      }
    });

    return {
      isTc2Device,
      savingAudioSettings,
      audioMode,
      audioModeExplanation,
      audioModeOptions,
      settings,
      currentWindowsType,
      fields,
      showCustomModal,
      useLowPowerMode,
      toggleUseLowPowerMode,
      setDefaultRecordingWindows,
      set24HourRecordingWindows,
      settingsTable,
      recordingWindowSetting,
      customRecordingWindowStart,
      customRecordingWindowStop,
      customPowerWindowStart,
      customPowerWindowStop,
    };
  },
  computed: mapState({
    dayOfSnapshot: function () {
      if (this.software.result.dateTime) {
        return toStringTodayYesterdayOrDate(
          new Date(this.software.result.dateTime),
        );
      }
    },
    timeOfSnapshot: function () {
      if (this.software.result.dateTime) {
        const thisDate = new Date(this.software.result.dateTime);
        return thisDate.toLocaleTimeString();
      }
    },
  }),
});
